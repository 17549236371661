<template>
<div class="">
    <h1 class="mb-3 f-12">ワンタイムパスワードの入力</h1>
    <p>登録したメールアドレスにワンタイムパスワードを送信しました</p>
    <v-card class="pa-6 verify_box relative-center">
        <v-text-field v-model="code" label="ワンタイムパスワード" outlined maxlength="6" />
    </v-card>
        <v-btn text class="f-blue mt-6" @click="codeSend(true)">ワンタイムパスワードを再送する</v-btn>
        
    <Snackbar ref="snack" />
</div>
</template>

<script>
import axios from "axios"
import Snackbar from "@/components/snackbar.vue"
export default {
    data() {
        return {
            code: ""
        }
    },
    mounted() {
        this.codeSend()
    },
    components:{
        Snackbar
    },
    methods: {
        async codeSend(val) {
            const data = {
                check: true
            }

            const response = await axios.post("/user/oneTimeCodeSend", data)

            if (response.data.error) {
                this.nowPasswordRules = [response.data.error]
            }
            if (response.data.ok) {
                this.dialog = true
                if(val){
             this.$refs.snack.snack = true
            this.$refs.snack.message = "再送しました"
                }
            }
        },
    },
    watch: {
        async code(val) {
            if (val.length == 6) {
                const data = {
                    "code": val,
                    "verified": 2
                }
                const response = await axios.post("/user/oneTimePassCheck", data)

                if (response.data.timeout) {
                    this.codeRules = ["30分が過ぎました。再度、やりなおしてください"]
                } else if (response.data.error) {
                    this.codeRules = [response.data.error]
                }

                if (response.data.ok) {
                    await this.$store.dispatch("user/verifyChange", 2);

                    this.$router.push("/user")
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/verify.scss";
</style>
